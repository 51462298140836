import { Modal } from "@mui/base";
import {
  Backdrop,
  Box,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeAboutModalOpen } from "../redux/metaSlice";
import { Clear } from "@mui/icons-material";

const AboutModal = () => {
  // Hooks
  const open = useSelector((state) => state.meta.aboutModalOpen) || false;
  const dispatch = useDispatch();

  // Component
  return (
    <Backdrop open={open}>
      <Modal
        open={open}
        onClose={() => dispatch(changeAboutModalOpen({ open: false }))}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 1,
            zIndex: (theme) => theme.zIndex.drawer + 2,
          }}
        >
          <Toolbar>
            <Box display="flex" flexDirection="row" sx={{ width: "100%" }}>
              <Box justifyContent="left" alignSelf="center" flexGrow={1}>
                <Typography variant="h6" align="left">
                  About Ghost ID 2.0
                </Typography>
              </Box>
              <Box justifyContent="right" alignSelf="center">
                <IconButton
                  onClick={() =>
                    dispatch(changeAboutModalOpen({ open: false }))
                  }
                  size="small"
                >
                  <Clear />
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
          <Divider />
          <Stack direction="column">
            <Typography
              paragraph
              variant="body2"
              align="left"
              sx={{ p: 1, wordWrap: "break-word", mb: 0 }}
              color={(theme) => theme.palette.text.secondary}
            >
              Welcome back. I have a new tool ready for you. Introducing Ghost
              ID 2.0, now slightly more streamlined for use in Phasmophobia's
              prestige update (0.9.0+).
            </Typography>
            <Typography
              paragraph
              variant="body2"
              align="left"
              sx={{ p: 1, wordWrap: "break-word", mb: 0 }}
            >
              You may have noticed all the bonus objectives being removed. This
              is intentional as I feel that the amazing Phasmophobia dev team
              has created a much better streamlined in-game journal for tracking
              those elements of the game.
            </Typography>
            <Typography
              paragraph
              variant="body2"
              align="left"
              color={(theme) => theme.palette.text.secondary}
              sx={{ p: 1, wordWrap: "break-word", mb: 0 }}
            >
              Ghost ID will now soley focus on the identificiation of ghosts and
              will be adding in community discovered tips & tricks. As well as
              adding features that you may need, such as timer to track hunts
              and incense.
            </Typography>
            <Typography
              paragraph
              variant="body2"
              align="left"
              sx={{ p: 1, wordWrap: "break-word", mb: 0 }}
            >
              Sadly there is some bad news: many of the old packages and
              frameworks used in the older version of Ghost ID has since been
              removed from React. Meaning many unique features such as
              translations will have to be reimplemented from scratch. This
              takes time, and for now... I would rather focus on new feratures
              and better code.
            </Typography>
            <Typography
              paragraph
              variant="body2"
              align="left"
              color={(theme) => theme.palette.text.secondary}
              sx={{ p: 1, wordWrap: "break-word", mb: 0 }}
            >
              There is more to come, so stay tuned. As always, thank you for
              your continued support.
            </Typography>
            <Typography
              paragraph
              variant="i"
              align="left"
              sx={{
                p: 1,
                wordWrap: "break-word",
                mb: 0,
                fontStyle: "italic",
              }}
            >
              - thelunararmy
            </Typography>
          </Stack>
        </Box>
      </Modal>
    </Backdrop>
  );
};

export default AboutModal;
