import { createSlice } from "@reduxjs/toolkit";
import {
  DOTSPROJECTOR,
  EMFLEVEL5,
  FREEZINGTEMPERATURES,
  GHOSTORBS,
  GHOSTWRITING,
  SPIRITBOX,
  ULTRAVOILET,
  all_evidence,
} from "../enums/evidence_enum";
import { EXCLUDE, OFF, ON } from "../enums/button_state_enum";
import { all_ghosts } from "../enums/ghost_enum,";
import { ghostDesigns } from "../utility/ghostDesigns";

const generateGhosts = (state) => {
  const status_exclude = all_evidence.filter(
    (evidence) => state.status[evidence] === EXCLUDE
  );
  const status_on = all_evidence.filter(
    (evidence) => state.status[evidence] === ON
  );
  const impossible = all_ghosts.filter(
    (ghost) =>
      ghostDesigns[ghost].evidence.filter((sign) =>
        status_exclude.includes(sign)
      ).length === 0
  );
  const possible = impossible.filter(
    (ghost) =>
      ghostDesigns[ghost].evidence.filter((sign) => status_on.includes(sign))
        .length === status_on.length
  );
  const remaining = Array.from(
    new Set(possible.map((ghost) => ghostDesigns[ghost].evidence).flat())
  );
  return { impossible, possible, remaining, known: status_on };
};

const initialState = {
  status: {
    [EMFLEVEL5]: OFF,
    [ULTRAVOILET]: OFF,
    [GHOSTWRITING]: OFF,
    [FREEZINGTEMPERATURES]: OFF,
    [DOTSPROJECTOR]: OFF,
    [GHOSTORBS]: OFF,
    [SPIRITBOX]: OFF,
  },
  possibleGhosts: [...all_ghosts],
  impossibleGhosts: [],
  knownEvidence: [],
  remainingEvidence: [...all_evidence],
  excludedGhosts: [],
  expandedGhosts: [],
};

export const evidenceSlice = createSlice({
  name: "evidence",
  initialState,
  reducers: {
    updateEvidence: (state, action) => {
      const { type } = action.payload;
      state.status[type] += 1;
      state.status[type] %= 3;
      const { impossible, possible, remaining, known } = generateGhosts(state);
      state.possibleGhosts = [...possible];
      state.impossibleGhosts = [...impossible];
      state.remainingEvidence = [...remaining];
      state.knownEvidence = [...known];
    },
    resetEvidence: () => initialState,
    updateExcludeGhost: (state, action) => {
      if (state.excludedGhosts.includes(action.payload.ghost)) {
        state.excludedGhosts = state.excludedGhosts.filter(
          (e) => e !== action.payload.ghost
        );
      } else {
        state.excludedGhosts.push(action.payload.ghost);
      }
    },
  },
});

export const { updateEvidence, resetEvidence, updateExcludeGhost } =
  evidenceSlice.actions;
export const evidenceReducer = evidenceSlice.reducer;
