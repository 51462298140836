import { useDispatch, useSelector } from "react-redux";
import { updateEvidence } from "../redux/evidenceSlice";
import { IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { evidenceDesigns } from "../utility/evidenceDesigns";
import { EXCLUDE, OFF, ON } from "../enums/button_state_enum";
import { useTheme } from "@emotion/react";

const EvidenceButton = ({ type }) => {
  // Hooks
  const dispatch = useDispatch();
  const type_state = useSelector((state) => state.evidence.status[type]);
  const isNotPossible = useSelector(
    (state) =>
      !state.evidence.remainingEvidence.includes(type) && type_state === OFF
  );
  const theme = useTheme();

  // Props
  const design = evidenceDesigns[type];
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  // Component
  return (
    <Stack
      direction="column"
      width={isMobile ? "36px" : "55px"}
      alignItems="center"
      spacing={0.5}
      padding={1}
    >
      <IconButton
        onClick={() => dispatch(updateEvidence({ type }))}
        disabled={isNotPossible}
        size={isMobile ? "small" : "large"}
        disableFocusRipple
        sx={{
          bgcolor: type_state === ON ? design.color : "inherit",
          "&:hover": {
            bgcolor: isMobile
              ? type_state === ON
                ? design.color
                : "inherit"
              : `${design.color}60`,
          },
          border: `2px solid ${
            type_state === EXCLUDE
              ? theme.palette.text.disabled
              : isNotPossible
              ? theme.palette.text.disabled
              : design.color
          }50`,
        }}
      >
        {type_state < EXCLUDE ? (
          <design.icon
            sx={{
              color: isNotPossible
                ? theme.palette.text.disabled
                : type_state === OFF
                ? design.color
                : "inherit",
            }}
          />
        ) : (
          <Clear sx={{ color: design.color }} />
        )}
      </IconButton>

      <Typography
        variant="caption"
        fontSize={isMobile ? "9px" : "12px"}
        lineHeight="1.2"
        display="flex"
        color={
          isNotPossible
            ? theme.palette.text.disabled
            : type_state === OFF
            ? `${design.color}80`
            : type_state === ON
            ? design.color
            : theme.palette.text.disabled
        }
        gutterBottom
        sx={{ textDecoration: isNotPossible ? "line-through" : "" }}
      >
        {design.name}
      </Typography>
    </Stack>
  );
};

export default EvidenceButton;
