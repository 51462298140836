export const // Matches In-Game Journal page 24
  SPIRIT = 1,
  WRAITH = 2,
  PHANTOM = 3,
  POLTERGEIST = 4,
  BANSHEE = 5,
  JINN = 6,
  MARE = 7,
  REVENANT = 8,
  SHADE = 9,
  DEMON = 10,
  YUREI = 11,
  ONI = 12,
  YOKAI = 13,
  HANTU = 14,
  GORYO = 15,
  MYLING = 16,
  ONRYO = 17,
  THETWINS = 18,
  RAIJU = 19,
  OBAKE = 20,
  THEMIMIC = 21,
  MOROI = 22,
  DEOGEN = 23,
  THAYE = 24;

export const all_ghosts = [
  SPIRIT,
  WRAITH,
  PHANTOM,
  POLTERGEIST,
  BANSHEE,
  JINN,
  MARE,
  REVENANT,
  SHADE,
  DEMON,
  YUREI,
  ONI,
  YOKAI,
  HANTU,
  GORYO,
  MYLING,
  ONRYO,
  THETWINS,
  RAIJU,
  OBAKE,
  THEMIMIC,
  MOROI,
  DEOGEN,
  THAYE,
];
