import { configureStore } from "@reduxjs/toolkit";
import { metaSliceReducer } from "./metaSlice";
import { evidenceReducer } from "./evidenceSlice";

export const store = configureStore({
  reducer: {
    meta: metaSliceReducer,
    evidence: evidenceReducer,
  },
  devTools: true,
});
