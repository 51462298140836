import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";
import { dark_theme, light_theme } from "../theme/theme";
import { CssBaseline } from "@mui/material";

const Themer = ({ children }) => {
  const currentTheme = useSelector((state) => state.meta.theme);

  return (
    <ThemeProvider theme={currentTheme === "dark" ? dark_theme : light_theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default Themer;
