import {
  Autorenew,
  Brightness2,
  InfoOutlined,
  WbSunny,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Fade,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeAboutModalOpen, invertTheme } from "../redux/metaSlice";
import { resetEvidence } from "../redux/evidenceSlice";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import AboutModal from "./AboutModal";

const TopBar = () => {
  // Hooks
  const dispatch = useDispatch();
  const currentTheme = useSelector((state) => state.meta.theme);
  const theme = useTheme();
  const [resetTooltipHasShownOnce, setResetTooltipHasShownOnce] =
    useState(false);
  const [resetTooltipOpen, setResetTooltipOpen] = useState(false);
  const evidenceIsSelected = useSelector(
    (state) => state.evidence.knownEvidence.length > 0
  );

  // Effects
  useEffect(() => {
    if (evidenceIsSelected && !resetTooltipHasShownOnce) {
      setResetTooltipOpen(true);
      setResetTooltipHasShownOnce(true);
      console.log("wtf");
    }
  }, [evidenceIsSelected, resetTooltipHasShownOnce]);

  // Handlers
  const handleThemeChange = () => {
    // Change theme from dark to light
    dispatch(invertTheme());
  };
  const handleAboutModal = () => {
    dispatch(changeAboutModalOpen({ open: true }));
  };
  const handleEvidenceReset = () => {
    dispatch(resetEvidence());
  };

  // Component
  return (
    <AppBar
      sx={{
        background: theme.palette.primary.main,
        width: "100%",
        position: "fixed",
        top: 0,
      }}
    >
      <Toolbar>
        {/* Logo */}
        <img
          src={`./images/ghostid_${
            currentTheme === "dark" ? "white" : "black"
          }.png`}
          alt="ghostid_logo"
          width="100px"
        />
        {/* Space */}
        <Box sx={{ flexGrow: 1 }} />
        {/* Buttons */}
        <Stack gap={2} direction="row" sx={{ mr: 0 }}>
          <Tooltip
            title={`${currentTheme === "dark" ? "Light" : "Dark"} Mode`}
            arrow
          >
            <IconButton color="inherit" onClick={() => handleThemeChange()}>
              {currentTheme === "dark" ? <WbSunny /> : <Brightness2 />}
            </IconButton>
          </Tooltip>

          <Tooltip title="About GhostID" arrow>
            <div>
              <IconButton color="inherit" onClick={() => handleAboutModal()}>
                <InfoOutlined />
              </IconButton>
              <AboutModal />
            </div>
          </Tooltip>

          <Tooltip
            title="Reset investigation"
            arrow
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 1000 }}
            open={resetTooltipOpen}
            onOpen={() => setResetTooltipOpen(true)}
            onClose={() => setResetTooltipOpen(false)}
          >
            <IconButton color="inherit" onClick={() => handleEvidenceReset()}>
              <Autorenew />
            </IconButton>
          </Tooltip>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
