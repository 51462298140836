import {
  AccessTime,
  Accessibility,
  AddAPhotoOutlined,
  AlignHorizontalCenter,
  AssistWalker,
  BackHand,
  BakeryDining,
  BlurLinear,
  Bolt,
  CalendarMonth,
  Campaign,
  Category,
  ChargingStation,
  CopyAll,
  DarkMode,
  DirectionsRun,
  DoNotTouch,
  DoneAll,
  ExitToApp,
  FaceRetouchingNatural,
  FilterDrama,
  Fireplace,
  FireplaceTwoTone,
  FlashOff,
  FollowTheSigns,
  GroupRemove,
  Groups3,
  Highlight,
  HighlightOff,
  HourglassBottom,
  Layers,
  LegendToggle,
  LensBlur,
  LightbulbCircle,
  LocalFireDepartment,
  MedicationLiquid,
  MeetingRoom,
  MicOff,
  MusicNote,
  NearbyError,
  NoLuggage,
  NoiseControlOff,
  ScreenshotMonitor,
  SelfImprovement,
  SevereCold,
  ShareLocation,
  SignalCellular2BarOutlined,
  SmokingRooms,
  Snowshoeing,
  StarsOutlined,
  Thermostat,
  TouchApp,
  TransferWithinAStation,
  TrendingDown,
  VisibilityOffOutlined,
  VoiceChat,
  WarningAmber,
} from "@mui/icons-material";
import {
  DOTSPROJECTOR,
  EMFLEVEL5,
  FREEZINGTEMPERATURES,
  GHOSTORBS,
  GHOSTWRITING,
  SPIRITBOX,
  ULTRAVOILET,
} from "../enums/evidence_enum";
import {
  BANSHEE,
  DEMON,
  DEOGEN,
  GORYO,
  HANTU,
  JINN,
  MARE,
  MOROI,
  MYLING,
  OBAKE,
  ONI,
  ONRYO,
  PHANTOM,
  POLTERGEIST,
  RAIJU,
  REVENANT,
  SHADE,
  SPIRIT,
  THAYE,
  THEMIMIC,
  THETWINS,
  WRAITH,
  YOKAI,
  YUREI,
} from "../enums/ghost_enum,";
import { evidenceDesigns } from "./evidenceDesigns";

export const ghostDesigns = {
  [SPIRIT]: {
    name: "Spirit",
    evidence: [EMFLEVEL5, SPIRITBOX, GHOSTWRITING],
    hint: "Cannot hunt for 3 minutes after being calmed with incense",
    adv: [
      {
        icon: SmokingRooms,
        text: "Initiate a hunt. When the ghost gets close use an incense and start a timer.",
      },
      {
        icon: AlignHorizontalCenter,
        text: "Place a crucifix in the ghost room. If there is no hunt after 3 minutes then it may be a Spirit",
      },
    ],
  },
  [WRAITH]: {
    name: "Wraith",
    evidence: [EMFLEVEL5, SPIRITBOX, DOTSPROJECTOR],
    hint: "Refuses to touch salt, even during a hunt.",
    adv: [
      {
        icon: LensBlur,
        text: "Place salt along corridors and doorways, especially near the entrances to the ghost room.",
      },
      {
        icon: FollowTheSigns,
        text: "Initiate a hunt and get the ghost to follow you through the salt piles.",
      },
      {
        icon: DoneAll,
        text: "If none of the salt is disturbed then the ghost is definitely a wraith.",
      },
    ],
  },
  [PHANTOM]: {
    name: "Phantom",
    evidence: [SPIRITBOX, ULTRAVOILET, DOTSPROJECTOR],
    hint: "Disappears after having its photo taken during an event. Hard to see during a hunt",
    adv: [
      {
        icon: AddAPhotoOutlined,
        text: "Wait for a ghost event where the ghost reveals itself. Take a photo of the ghost.",
      },
      {
        icon: DoneAll,
        text: "If the event continues but the ghost is no longer visible then it is definitely a phantom.",
      },
      {
        icon: VisibilityOffOutlined,
        text: `Initiate a hunt. If the ghost is invisible for a long time while "flickering", then it is definitely a phantom.`,
      },
    ],
  },
  [POLTERGEIST]: {
    name: "Poltergeist",
    evidence: [SPIRITBOX, ULTRAVOILET, GHOSTWRITING],
    hint: "Throws multiple objects a great distance simultaneously.",
    adv: [
      {
        icon: Layers,
        text: "Find the ghost room. Place many objects into a pile near the entrance of the room, or on a table.",
      },
      {
        icon: BakeryDining,
        text: "If the ghost scatters all the objects at the same time during an interaction, then it is definitely a poltergeist.",
      },
      {
        icon: Category,
        text: "Initiate a hunt. If the ghost throws every object near it with great force, then it is possibly a poltergeist.",
      },
    ],
  },
  [BANSHEE]: {
    name: "Banshee",
    evidence: [ULTRAVOILET, GHOSTORBS, DOTSPROJECTOR],
    hint: "Will target one specific investigator at all time, and loves to sing.",
    adv: [
      {
        icon: Campaign,
        text: "Use a parabolic microphone to listen for wailing or screeching noises while ghost is not hunting.",
      },
      {
        icon: MusicNote,
        text: "If the ghost is constantly singing during ghost events and hunts then it is possibly a banshee.",
      },
      {
        icon: FaceRetouchingNatural,
        text: "Initiate a hunt. If the ghost is only chasing one particular investigator instead of the closest one then it definitely a banshee.",
      },
      {
        icon: TouchApp,
        text: "[Dangerous] Initiate a hunt. If the ghost does not kill any other investigator, then it is definitely a banshee.",
      },
    ],
  },
  [JINN]: {
    name: "Jinn",
    evidence: [EMFLEVEL5, ULTRAVOILET, FREEZINGTEMPERATURES],
    hint: "Rapidly charges investigators during hunts while the fuse box is on.",
    adv: [
      {
        icon: SignalCellular2BarOutlined,
        text: "Leave an EMF reader near the fuse box. If the ghost interacts with the box without turning off the power and leaves an EMF reading of 2 or 5 then it is definitely a Jinn.",
      },
      {
        icon: DoNotTouch,
        text: "The Jinn can never intentionally turn the fuse box off, unless by accidently exceeding the light limit of the location.",
      },
      {
        icon: DirectionsRun,
        text: "Initiate a hunt. Take note if the ghost rapidly accelerates towards you from far away, but slows to regular speed when close-by.",
      },
      {
        icon: AssistWalker,
        text: "Turn off the fuse box. Initiate a second hunt. If the ghost no longer accelerates from far away, then it is definitely a Jinn.",
      },
    ],
  },
  [MARE]: {
    name: "Mare",
    evidence: [SPIRITBOX, GHOSTORBS, GHOSTWRITING],
    hint: "Cannot turn lights on, and will attempt to make everything dark.",
    adv: [
      {
        icon: FlashOff,
        text: "A Mare is the only ghost that can immediately turn a light off without a ghost event after an investigator switches it on.",
      },
      {
        icon: DarkMode,
        text: "If a hunt starts at 40% sanity instead of 60% while the investigator is in the dark then it may be a Mare.",
      },
      {
        icon: LightbulbCircle,
        text: "This ghost can never turn a light on, so if a light ever turns on by itself then the ghost cannot be a Mare.",
      },
    ],
  },
  [REVENANT]: {
    name: "Revenant",
    evidence: [GHOSTORBS, GHOSTWRITING, FREEZINGTEMPERATURES],
    hint: "Very slow while searching for investigators during a hunt, but very fast when a target is seen.",
    adv: [
      {
        icon: Snowshoeing,
        text: "Initiate a hunt and hide. Listen to the ghost's footsteps and identify if its footsteps are slow.",
      },
      {
        icon: DirectionsRun,
        text: "Let the ghost chase you during a hunt. Note if its speed increases significantly the moment it sees you. If it does, then it is possibly a revenant.",
      },
    ],
  },
  [SHADE]: {
    name: "Shade",
    evidence: [EMFLEVEL5, GHOSTWRITING, FREEZINGTEMPERATURES],
    hint: "Cannot start a hunt while an investigator is in the room with it.",
    adv: [
      {
        icon: AlignHorizontalCenter,
        text: "Find the ghost room. Place a crucifix in the room. Get the average sanity below 35%.",
      },
      {
        icon: HourglassBottom,
        text: "Stay in the ghost room. If the ghost never initiates a hunt while you are in the room, then it is possibly a shade.",
      },
      {
        icon: SelfImprovement,
        text: "The shade is known for its inactivity when investigators are nearby. Very low interactions or events can indicate a shade.",
      },
    ],
  },
  [DEMON]: {
    name: "Demon",
    evidence: [ULTRAVOILET, GHOSTWRITING, FREEZINGTEMPERATURES],
    hint: "Can hunt at any sanity level, and 1 minute after being calmed with incense.",
    adv: [
      {
        icon: MedicationLiquid,
        text: "Find the ghost room. Place a crucifix in the room. Use sanity medication to increase sanity over 80%.",
      },
      {
        icon: FireplaceTwoTone,
        text: "If the ghost attempts to hunt by using a crucifix charge while sanity is high then it is definitely a demon.",
      },
      {
        icon: AccessTime,
        text: "After a crucifix charge is used start a timer. If the ghost attempts another hunt between 20 to 25 seconds, then it is definitely a demon.",
      },
      {
        icon: SmokingRooms,
        text: "Use incense near the ghost, start a timer. If the ghost starts a hunt between 60 and 90 seconds, then it is definitely a demon.",
      },
    ],
  },
  [YUREI]: {
    name: "Yurei",
    evidence: [GHOSTORBS, FREEZINGTEMPERATURES, DOTSPROJECTOR],
    hint: "Can get trapped inside the ghost room for 60 seconds after being exposed to incense.",
    adv: [
      {
        icon: ShareLocation,
        text: "Find the ghost room. Place ghost detection equipment like DOTS Projectors, Salt, and Motion Sensors around the room.",
      },
      {
        icon: SmokingRooms,
        text: "If the ghost causes an interaction anywhere other than the ghost room then use an incense near it.",
      },
      {
        icon: ExitToApp,
        text: "If the ghost is instantly teleported to the ghost room and only causes interactions within that room for 60 seconds then it is definitely a yurei.",
      },
      {
        icon: MeetingRoom,
        text: "If a door is ever loudly slammed without a ghost event occurring and your sanity drops instantly by 15% then it is definitely a yurei.",
      },
    ],
  },
  [ONI]: {
    name: "Oni",
    evidence: [EMFLEVEL5, FREEZINGTEMPERATURES, DOTSPROJECTOR],
    hint: "Very visible during a hunt, and cannot manifest into a ball of smoke.",
    adv: [
      {
        icon: HighlightOff,
        text: "The oni can never manifest itself as a ball of smoke and spook the investigator with a loud hissing noise.",
      },
      {
        icon: Accessibility,
        text: "If the ghost frequently manifests itself using its full body instead a shadow or partial form, then it possibly an oni.",
      },
      {
        icon: DirectionsRun,
        text: "Initiate a hunt. If the ghost is constantly visible with only a few seconds of invisibility while flickering, then it is definitely an oni.",
      },
    ],
  },
  [YOKAI]: {
    name: "Yokai",
    evidence: [SPIRITBOX, GHOSTORBS, DOTSPROJECTOR],
    hint: "Cannot hear or detect electronic equipment during a hunt unless very close to an investigator.",
    adv: [
      {
        icon: NoiseControlOff,
        text: "If the ghost starts a hunt with sanity between 80% and 50% when investigators are being audibly loud then it is possibly a yokai.",
      },
      {
        icon: MicOff,
        text: "Initiate a hunt and hide from the ghost. As soon as you hear footsteps attempt to communicate with the ghost audibly. If the ghost doesn't immediately chase you, then it is possibly a yokai.",
      },
    ],
  },
  [HANTU]: {
    name: "Hantu",
    evidence: [ULTRAVOILET, GHOSTORBS, FREEZINGTEMPERATURES],
    hint: "Very fast in cold temperatures, and very slow in warm areas. Loves to turn fuse box off.",
    adv: [
      {
        icon: Thermostat,
        text: "Keep the fuse box on to increase the average temperature of the location. Initiate a hunt and note the ghost's footstep pace and speed.",
      },
      {
        icon: SevereCold,
        text: "Turn the fuse box off to reduce the average temperature. Initiate a hunt again, if the ghost is significantly faster without slowing down, then it is possibly a hantu.",
      },
      {
        icon: FilterDrama,
        text: "If the ghost ever creates a cold breath cloud while hunting, then it is definitely a hantu.",
      },
      {
        icon: evidenceDesigns[FREEZINGTEMPERATURES].icon,
        text: "On harder difficulties: The hantu will always display Freezing Temperatures as forced evidence.",
        forced: true,
      },
    ],
  },
  [GORYO]: {
    name: "Goryo",
    evidence: [EMFLEVEL5, ULTRAVOILET, DOTSPROJECTOR],
    hint: "Cannot be seen with the naked eye unless hunting or performing events, and can never change the ghost room.",
    adv: [
      {
        icon: ScreenshotMonitor,
        text: "Place a video camera aimed at a DOTS projection. Evacuate the room and monitor the camera feed from the truck. If this is the only way see ghost's DOTS form, then it is definitely a goryo.",
      },
      {
        icon: NoLuggage,
        text: "On Professional or higher difficulties: If the ghost never moves the ghost room to another room after a long investigation then it is definitely a goryo.",
      },
      {
        icon: evidenceDesigns[DOTSPROJECTOR].icon,
        text: "On harder difficulties: The goryo will always display DOTS Projections as forced evidence.",
        forced: true,
      },
    ],
  },
  [MYLING]: {
    name: "Myling",
    evidence: [EMFLEVEL5, ULTRAVOILET, GHOSTWRITING],
    hint: "Very quiet during hunts, investigators will struggle to hear this silent hunter.",
    adv: [
      {
        icon: Campaign,
        text: "Use a parabolic microphone outside of a hunt. If the ghost frequently creates spooky noises, then it is possibly a myling.",
      },
      {
        icon: Highlight,
        text: "Drop a flashlight on the ground inside a hiding spot. Initiate a hunt. If you cannot hear the ghost or footsteps before the light flickers, then it is definitely a myling.",
      },
    ],
  },
  [ONRYO]: {
    name: "Onryo",
    evidence: [SPIRITBOX, GHOSTORBS, FREEZINGTEMPERATURES],
    hint: "Cannot hunt near a lit candle or open fire, but will always hunt after extinguishing three flames.",
    adv: [
      {
        icon: LocalFireDepartment,
        text: "Place a lit candle next a crucifix. If the candle is extinguished exactly three times and a hunt is prevented with the crucifix, then it is possibly an onryo.",
      },
      {
        icon: Fireplace,
        text: "Use medication to replenish sanity over 80%. Light three candles in one room. If all three flames are extinguished and the ghost starts a hunt while sanity is high, then it is definitely an onryo.",
      },
    ],
  },
  [THETWINS]: {
    name: "The Twins",
    evidence: [EMFLEVEL5, SPIRITBOX, FREEZINGTEMPERATURES],
    hint: "Can perform two interactions in different rooms at the same time. One hunts fast... the other hunts slow...",
    adv: [
      {
        icon: GroupRemove,
        text: "Despite the name, 'The Twins', is only ever one ghost during hunts. However, this ghost can interact with two items very far apart.",
      },
      {
        icon: TransferWithinAStation,
        text: "Initiate a hunt. The Twins is either slower than an investigator's regular walk speed or faster.",
      },
      {
        icon: LegendToggle,
        text: "Repeat hunts and take note of the ghost's base speed. If you discover this ghost is slow in one hunt and fast in another then it is definitely the Twins.",
      },
      {
        icon: NearbyError,
        text: "One of the ghosts is not affected by a crucifix, therefor if you see the ghost appear untop of a usable crucifix at the start of the hunt, then it is definitely the Twins.",
      },
    ],
  },
  [RAIJU]: {
    name: "Raiju",
    evidence: [EMFLEVEL5, GHOSTORBS, DOTSPROJECTOR],
    hint: "Moves very quickly when near electronic equipment, even motion sensors and discarded equipment gives it speed.",
    adv: [
      {
        icon: Bolt,
        text: "If the ghost rapidly gains and maintains speed while near electronic equipment then is might be a raiju.",
      },
      {
        icon: ChargingStation,
        text: "Place several 'on' electronic equipment in a long line. Initiate a hunt and get the ghost to chase you, take note if its speed increases significantly.",
      },
      {
        icon: AssistWalker,
        text: "Remove all electronic equipment from the location and initiate another hunt. If the ghost is slower and does not accelerate then it is definitely a raiju.",
      },
      {
        icon: WarningAmber,
        text: "Beware: Electronic equipment held by any investigator will give the ghost speed. Remove electronics from person if a raiju is suspected. Environment electronics do not empower a raiju.",
      },
    ],
  },
  [OBAKE]: {
    name: "Obake",
    evidence: [EMFLEVEL5, ULTRAVOILET, GHOSTORBS],
    hint: "Leaves six fingered ultraviolet evidence, and can sometimes change its form during a hunt.",
    adv: [
      {
        icon: BackHand,
        text: "Encountering a six fingered handprint, or a two fingered fingerprint on a light switch, definitely proves the ghost is an obake.",
      },
      {
        icon: Groups3,
        text: "If the ghost changes its physical form to a completely different entity during a hunt while flickering then the ghost is definitely an obake.",
      },
      {
        icon: evidenceDesigns[ULTRAVOILET].icon,
        text: "On harder difficulties: The obake will always display Ultraviolet as forced evidence.",
        forced: true,
      },
    ],
  },
  [THEMIMIC]: {
    name: "The Mimic",
    evidence: [SPIRITBOX, ULTRAVOILET, FREEZINGTEMPERATURES, GHOSTORBS],
    forced: GHOSTORBS,
    hint: "Can copy the abilities of any other ghost, but can be identified with four forms of evidence.",
    adv: [
      {
        icon: CopyAll,
        text: "If the ghost suddenly changes its behavior or ability then it may be the Mimic. However, the Mimic will not change its physical form, nor can it change during a hunt.",
      },
      {
        icon: evidenceDesigns[GHOSTORBS].icon,
        text: "On harder difficulties: The Mimic will always display Ghost Orbs as forced evidence. This includes game modes where zero evidence is enabled, in such modes if Ghost Orbs are present the the ghost is definitely the Mimic.",
        forced: true,
      },
    ],
  },
  [MOROI]: {
    name: "Moroi",
    evidence: [SPIRITBOX, GHOSTWRITING, FREEZINGTEMPERATURES],
    hint: "A dangerous ghost that moves faster during hunts when the investigators have less sanity.",
    adv: [
      {
        icon: StarsOutlined,
        text: "After an investigator gets a response from a Spirit Box they will be cursed, causing sanity to be drained passively faster. If this is observed then the ghost is definitely a moroi.",
      },
      {
        icon: DirectionsRun,
        text: "If the ghost moves faster when the average sanity of all investigators is lower, then it is possibly a moroi.",
      },
      {
        icon: SmokingRooms,
        text: "Dangerous: Initiate a hunt. If the ghost is confused, slowed, or stunned for 8 to 11 seconds (instead of 5 to 7) when in contact with incense, then it is definitely a moroi.",
      },
      {
        icon: evidenceDesigns[SPIRITBOX].icon,
        text: "On harder difficulties: A moroi will always display Spirit Box as forced evidence.",
        forced: true,
      },
    ],
  },
  [DEOGEN]: {
    name: "Deogen",
    evidence: [SPIRITBOX, GHOSTWRITING, DOTSPROJECTOR],
    hint: "Always knows the exact location of investigators during a hunt, but moves extremely slow when close to its prey.",
    adv: [
      {
        icon: VoiceChat,
        text: "There is a 33% chance of receiving a 'bull-like' breathing noise response from a Spirit Box. This definitely proves the ghost is a deogen.",
      },
      {
        icon: TrendingDown,
        text: "The tell tale sign of a deogen is its extremely low hunting speed when near an investigator. Without sprinting it can be avoided by calmly walking away.",
      },
      {
        icon: evidenceDesigns[SPIRITBOX].icon,
        text: "On harder difficulties: A deogen will always display Spirit Box as forced evidence.",
        forced: true,
      },
    ],
  },
  [THAYE]: {
    name: "Thaye",
    evidence: [GHOSTORBS, GHOSTWRITING, DOTSPROJECTOR],
    hint: "A ghost that becomes slower and less active over time. Very dangerous at the start of an investigation, beware!",
    adv: [
      {
        icon: BlurLinear,
        text: "The thaye favors DOTS Projections and Ghost Writing over all other activities, and will most likely reveal these evidence types faster than most other ghosts.",
      },
      {
        icon: DirectionsRun,
        text: "The thaye moves at a consistently fast speeds when hunting early during the investigation. This may indicate a thaye if subsequent hunts are slower.",
      },
      {
        icon: CalendarMonth,
        text: "The thaye rapidly ages over time, becoming slower and less active as the investigation prolongs. Using an Ouija board to ask for its age and getting increasing age responses confirms the ghost as definitely a thaye.",
      },
    ],
  },
};
