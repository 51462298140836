import {
  AcUnit,
  Fingerprint,
  Gradient,
  MenuBook,
  Radio,
  Router,
  ScatterPlot,
} from "@mui/icons-material";

import {
  DOTSPROJECTOR,
  EMFLEVEL5,
  FREEZINGTEMPERATURES,
  GHOSTORBS,
  GHOSTWRITING,
  SPIRITBOX,
  ULTRAVOILET,
} from "../enums/evidence_enum";

export const evidenceDesigns = {
  [EMFLEVEL5]: {
    name: "EMF Level-5",
    icon: Router,
    color: "#c20000",
  },
  [ULTRAVOILET]: {
    name: "Ultra Violet",
    icon: Fingerprint,
    color: "#16cc74",
  },
  [GHOSTWRITING]: {
    name: "Ghost Writing",
    icon: MenuBook,
    color: "#916816",
  },
  [FREEZINGTEMPERATURES]: {
    name: "Freezing Temp",
    icon: AcUnit,
    color: "#2e73db",
  },
  [DOTSPROJECTOR]: {
    name: "DOTS Projector",
    icon: Gradient,
    color: "#1f9123",
  },
  [GHOSTORBS]: {
    name: "Ghost Orbs",
    icon: ScatterPlot,
    color: "#7ec4b0",
  },
  [SPIRITBOX]: {
    name: "Spirit Box",
    icon: Radio,
    color: "#b536ff",
  },
};
