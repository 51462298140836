import "@fontsource/schoolbell";
import { useTheme } from "@emotion/react";
import "./App.css";
import TopBar from "./components/TopBar";
import { useDispatch, useSelector } from "react-redux";
import FootBar from "./components/FootBar";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";
import EvidenceButton from "./components/EvidenceButton";
import { all_evidence } from "./enums/evidence_enum";
import GhostCard from "./components/GhostCard";
import UnknownGhostCard from "./components/UnknownGhostCard";
import { ViewList, ViewModule } from "@mui/icons-material";
import { setCompactGhostCards } from "./redux/metaSlice";
import { useEffect } from "react";

function App() {
  // Hooks
  const possibleGhosts = useSelector((state) => state.evidence.possibleGhosts);
  const ghostCardsCompact = useSelector(
    (state) => state.meta.compactGhostCards
  );
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const excludedGhosts = useSelector((state) => state.evidence.excludedGhosts);
  const dispatch = useDispatch();
  const theme = useTheme();

  // Effects
  useEffect(() => {
    if (isMobile) {
      dispatch(setCompactGhostCards({ compact: true }));
    }
  });

  // Component
  return (
    <Box textAlign="center" sx={{ pt: "60px", pb: "100px" }}>
      <TopBar />
      <Container maxWidth={false} sx={{ pt: 1, gap: 1 }}>
        <Stack direction="column" spacing={1}>
          <Card sx={{ bgcolor: theme.palette.primary.main }}>
            <CardHeader
              title="Select evidence..."
              titleTypographyProps={{ variant: "h6", fontFamily: "Schoolbell" }}
            />
            <Divider />
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-evenly"
                useFlexGap
                flexWrap="wrap"
                sx={{ maxWidth: "sm", mx: "auto" }}
                spacing={1}
              >
                {all_evidence.map((type) => {
                  return <EvidenceButton key={`ev-btn-${type}`} type={type} />;
                })}
              </Stack>
            </CardContent>
          </Card>
          <Card sx={{ bgcolor: theme.palette.primary.main }}>
            <CardHeader
              title="Possible ghosts..."
              titleTypographyProps={{ variant: "h6", fontFamily: "Schoolbell" }}
              action={
                <ToggleButtonGroup size="small" value={ghostCardsCompact}>
                  <ToggleButton
                    value={false}
                    key="ghost-card-not-compact"
                    onClick={() =>
                      dispatch(setCompactGhostCards({ compact: false }))
                    }
                  >
                    <ViewModule />
                  </ToggleButton>
                  <ToggleButton
                    value={true}
                    key="ghost-card-compact"
                    onClick={() =>
                      dispatch(setCompactGhostCards({ compact: true }))
                    }
                  >
                    <ViewList />
                  </ToggleButton>
                </ToggleButtonGroup>
              }
            />
            <Divider />
            <CardContent>
              <Stack
                direction="row"
                justifyContent="center"
                useFlexGap
                flexWrap="wrap"
                spacing={1}
                sx={{ maxWidth: "md", mx: "auto" }}
              >
                {possibleGhosts &&
                  excludedGhosts &&
                  possibleGhosts
                    .filter((x) => !excludedGhosts.includes(x))
                    .concat(
                      excludedGhosts.filter((x) => possibleGhosts.includes(x))
                    )
                    .map((type) => {
                      return (
                        <GhostCard key={`ghost-card-id-${type}`} type={type} />
                      );
                    })}
                {possibleGhosts.length === 0 ? <UnknownGhostCard /> : <></>}
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Container>
      <FootBar />
    </Box>
  );
}

export default App;
