import { createSlice } from "@reduxjs/toolkit";

export const metaSlice = createSlice({
  name: "meta",
  initialState: {
    theme: "dark",
    aboutModalOpen: false,
    compactGhostCards: false,
  },
  reducers: {
    invertTheme: (state) => {
      state.theme = state.theme === "dark" ? "light" : "dark";
    },
    changeAboutModalOpen: (state, action) => {
      state.aboutModalOpen = Boolean(action.payload.open);
    },
    setCompactGhostCards: (state, action) => {
      state.compactGhostCards = Boolean(action.payload.compact);
    },
  },
});

export const { invertTheme, changeAboutModalOpen, setCompactGhostCards } =
  metaSlice.actions;
export const metaSliceReducer = metaSlice.reducer;
