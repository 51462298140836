import {
  AlignHorizontalCenter,
  AllOut,
  CurrencyFranc,
  Fitbit,
  Foundation,
  HideSource,
  KeyboardOptionKey,
  MultilineChart,
  PowerInput,
  StarBorder,
  Toll,
  WifiChannel,
} from "@mui/icons-material";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";

const UnknownGhostCard = () => {
  // Consts
  const randbetween = (min, max) =>
    Math.floor(Math.random() * (max - min + 1) + min);
  const default_text = "Unknown Entity";
  const scramble_text = (text) => {
    let words = text.split(" ");
    words = words.map((word) => {
      const capital = word.substring(0, 1);
      const rest = word
        .substring(1)
        .split("")
        .sort(() => 0.5 - Math.random())
        .join("");
      return capital + rest;
    });
    return words.join(" ");
  };

  // Hooks
  const random_spooky_icons = useMemo(
    () => [
      AlignHorizontalCenter,
      Foundation,
      HideSource,
      AllOut,
      CurrencyFranc,
      Fitbit,
      KeyboardOptionKey,
      MultilineChart,
      PowerInput,
      StarBorder,
      Toll,
      WifiChannel,
    ],
    []
  );
  const [rng, setRNG] = useState(random_spooky_icons.slice(3));
  const [titleText, setTitleText] = useState(default_text);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const rsi = random_spooky_icons.sort(() => 0.5 - Math.random());
      setRNG([rsi[0], rsi[1], rsi[2]]);
      if (titleText !== default_text || randbetween(0, 10) < 8) {
        setTitleText(default_text);
      } else {
        setTitleText(scramble_text(default_text));
      }
    }, randbetween(200, 2000));
    return () => clearTimeout(timeout);
  }, [rng, titleText, random_spooky_icons]);

  const rng_icons = {
    icon1: rng[0],
    icon2: rng[1],
    icon3: rng[2],
  };

  // Component
  return (
    <Card elevation={4}>
      <CardHeader
        title={
          <Typography
            sx={{
              fontSize: 24,
              fontFamily: "Schoolbell",
              color: "inherit",
            }}
          >
            {titleText}
          </Typography>
        }
        sx={{ pb: 0 }}
      />
      <CardContent>
        <Typography
          component="div"
          variant="body2"
          fontSize={10}
          sx={{
            color: (theme) =>
              titleText === default_text
                ? "inherit"
                : theme.palette.text.disabled,
          }}
        >
          ...something has gone terribly wrong.... this is impossible...
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "center", mb: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          useFlexGap
          flexWrap="wrap"
          sx={{ width: "200px", mx: "auto" }}
          spacing={0.5}
        >
          <rng_icons.icon1
            sx={{
              color: "inherit",
            }}
          />
          <rng_icons.icon2
            sx={{
              color: "inherit",
            }}
          />
          <rng_icons.icon3
            sx={{
              color: "inherit",
            }}
          />
        </Stack>
      </CardActions>
    </Card>
  );
};

export default UnknownGhostCard;
