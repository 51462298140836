import { createTheme } from "@mui/material";

export const light_theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#eeeeee",
    },
    secondary: {
      main: "#d32f2f",
    },
    error: {
      main: "#d50000",
    },
    text: {
      primary: "#303030",
      secondary: "#4a4a4a",
      disabled: "#cccccc",
    },
    background: {
      default: "#ffffff",
    },
  },
});

export const dark_theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#121212",
    },
    secondary: {
      main: "#d32f2f",
    },
    error: {
      main: "#d50000",
    },
    text: {
      primary: "#bbbbbb",
      secondary: "#777777",
      disabled: "#4a4a4a",
    },
    background: {
      default: "#0d0d0d",
    },
  },
});
