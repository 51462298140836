export const // Matched in-game journal page 22
  EMFLEVEL5 = "emf5",
  ULTRAVOILET = "uv",
  GHOSTWRITING = "writing",
  FREEZINGTEMPERATURES = "freezing",
  DOTSPROJECTOR = "dots",
  GHOSTORBS = "orbs",
  SPIRITBOX = "spiritbox";

export const all_evidence = [
  EMFLEVEL5,
  ULTRAVOILET,
  GHOSTWRITING,
  FREEZINGTEMPERATURES,
  DOTSPROJECTOR,
  GHOSTORBS,
  SPIRITBOX,
];
