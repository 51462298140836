import { AvTimer, VapingRooms } from "@mui/icons-material";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Collapse,
  Divider,
  LinearProgress,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import GenericTimer from "./GenericTimer";
import { createRef, useState } from "react";
import { msToMinSecs } from "../utility/timeConvert";
import { grey } from "@mui/material/colors";

const maxIncenseTime = 180 * 1000; // Spirit
const smudgeColor = "#DEB887";

const FootBar = () => {
  const incenseTimerRef = createRef(null);
  const [incenseTime, setIncenseTime] = useState(0);
  const [lastIncenseTime, setLastIncenseTime] = useState(0);

  // Component
  return (
    <Box
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        bgcolor: (theme) => theme.palette.primary.main,
      }}
    >
      <div labek="mounted-but-hidden" hidden>
        {/* remain invisible because if moved into collapse it breaks ref */}
        <GenericTimer
          max_time={maxIncenseTime}
          update={300}
          handleSetTime={setIncenseTime}
          ref={incenseTimerRef}
        />
      </div>
      <Collapse in={incenseTime > 0} timeout={50} unmountOnExit>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "100%", mr: 0, p: 2 }}>
            <LinearProgress
              variant="determinate"
              value={(incenseTime / maxIncenseTime) * 100}
              sx={{
                height: "20px",
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: grey[900],
                },
                [`& .${linearProgressClasses.bar}`]: {
                  borderRight: "solid 2px orange",
                  boxShadow: "-10px 0px 5px -5px red inset",
                  backgroundColor: grey[500],
                },
              }}
            />
          </Box>
          <Box sx={{ width: "30px", mx: 2 }}>
            <Typography variant="h5" fontFamily="Schoolbell">
              {msToMinSecs(incenseTime)}
            </Typography>
          </Box>
        </Box>
        <Divider />
      </Collapse>
      <BottomNavigation showLabels>
        <BottomNavigationAction
          label={
            !lastIncenseTime || incenseTime > 0
              ? "Incense Timer"
              : `Last Time: ${msToMinSecs(lastIncenseTime)}`
          }
          color="inherit"
          onClick={() => {
            const val = incenseTimerRef.current.start_timer();
            if (val) setLastIncenseTime(val ? val : 0);
          }}
          value="insence-timer"
          icon={<VapingRooms />}
          sx={{ color: incenseTime > 0 ? smudgeColor : "inherit" }}
        />
        <BottomNavigationAction
          label="?????"
          value="unknown-1"
          icon={<AvTimer />}
          disabled
        />
        <BottomNavigationAction
          label="?????"
          value="unknown-2"
          icon={<AvTimer />}
          disabled
        />
      </BottomNavigation>
    </Box>
  );
};

export default FootBar;
