import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Subs } from "react-sub-unsub";
import { useTimer } from "react-use-precision-timer";
import { msToMinSecs } from "../utility/timeConvert";

const GenericTimer = forwardRef(
  (
    { max_time = 6000, update = 1000, handleSetTime = () => {}, ...props },
    ref
  ) => {
    const timer = useTimer({
      delay: max_time,
      startImmediately: false,
      runOnce: true,
    });
    const [, setRenderTime] = useState(new Date().getTime());
    useEffect(() => {
      const subs = new Subs();
      subs.setTimeout(() => {
        handleSetTime(timer.getRemainingTime());
        return setRenderTime(new Date().getTime());
      }, update);
      return subs.createCleanup();
    });

    useImperativeHandle(ref, () => ({
      start_timer() {
        const time_remaining = timer.getRemainingTime();
        if (time_remaining > 0) {
          timer.stop();
          return time_remaining;
        } else {
          timer.start();
        }
      },
    }));

    return (
      <div {...props}>
        {timer.isStarted()
          ? msToMinSecs(timer.getRemainingTime())
          : msToMinSecs(max_time)}
      </div>
    );
  }
);

export default GenericTimer;
