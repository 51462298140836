import "@fontsource/schoolbell";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ghostDesigns } from "../utility/ghostDesigns";
import { evidenceDesigns } from "../utility/evidenceDesigns";
import { useDispatch, useSelector } from "react-redux";
import { Add, ExpandMore as ExpandMoreIcon, Remove } from "@mui/icons-material";
import styled from "@emotion/styled";
import { useState } from "react";
import { updateExcludeGhost } from "../redux/evidenceSlice";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton size="small" {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const FullGhostCard = ({ ghost, expanded, setExpanded, excluded }) => {
  // Hooks
  const known_evidence = useSelector((state) => state.evidence.knownEvidence);

  // Props
  const { name, evidence, hint, adv = [] } = ghost;
  return (
    <>
      <CardHeader
        title={
          <Typography
            sx={{
              fontSize: 24,
              fontFamily: "Schoolbell",
              textDecoration: excluded ? "line-through" : null,
              color: (theme) =>
                excluded ? theme.palette.text.disabled : "inherit",
            }}
          >
            {name}
          </Typography>
        }
        sx={{ pb: 0 }}
      />
      <CardContent sx={{ pb: 0 }}>
        <Typography
          component="div"
          variant="body2"
          sx={{
            fontSize: 10,
            textDecoration: excluded ? "line-through" : null,
            color: (theme) =>
              excluded ? theme.palette.text.disabled : "inherit",
          }}
        >
          {evidence.map((e) => evidenceDesigns[e].name).join(" • ")}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "center", my: 1 }}>
        {evidence.map((evidence) => {
          const design = evidenceDesigns[evidence];
          return (
            <design.icon
              key={`ghost-card-type-${design.name}`}
              sx={{
                width: 32,
                color: (theme) =>
                  excluded
                    ? theme.palette.text.disabled
                    : known_evidence.includes(evidence)
                    ? design.color
                    : "inherit",
              }}
            />
          );
        })}
      </CardActions>
      <Divider />
      <CardActions sx={{ ml: 2, my: 1, justifyContent: "center" }}>
        <Typography
          fontSize={12}
          color={(theme) => theme.palette.text.secondary}
          sx={{ pr: 1 }}
        >
          {hint}
        </Typography>
        <ExpandMore expand={expanded} onClick={() => setExpanded(!expanded)}>
          <ExpandMoreIcon
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            height: "20px",
            bgcolor: (theme) => theme.palette.background.default,
          }}
        >
          <Typography
            fontSize={11}
            textAlign="center"
            color={(theme) => theme.palette.text.primary}
            sx={{
              width: "100%",
            }}
          >
            Advanced identification techniques
          </Typography>
        </Box>
        <CardContent
          sx={{
            p: 1,
            border: `solid 4px ${(theme) => theme.palette.background.default}`,
          }}
        >
          <Stack spacing={0.5} direction="column">
            {adv &&
              adv.map((item, index) => (
                <div key={`${name}-adv-hunt-tech-tip-${index}`}>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <item.icon
                      sx={{
                        mx: 1,
                        border: item.forced ? "dotted 2px" : "",
                      }}
                    />
                    <Typography
                      fontSize={12}
                      color={(theme) => theme.palette.text.secondary}
                    >
                      {item.text}
                    </Typography>
                  </Box>
                  {item !== adv[adv.length - 1] && <Divider />}
                </div>
              ))}
          </Stack>
        </CardContent>
      </Collapse>
    </>
  );
};

const CompactGhostCard = ({ ghost, expanded, setExpanded, excluded }) => {
  // Hooks
  const known_evidence = useSelector((state) => state.evidence.knownEvidence);

  // Props
  const { name, evidence, hint, adv = [] } = ghost;
  return (
    <>
      <CardHeader
        title={
          <>
            <Typography
              variant="body"
              sx={{
                fontSize: 20,
                textAlign: "left",
                fontFamily: "Schoolbell",
                mr: 2,
                textDecoration: excluded ? "line-through" : null,
                color: (theme) =>
                  excluded ? theme.palette.text.disabled : "inherit",
              }}
            >
              {name}
            </Typography>
            {evidence.map((evidence) => {
              const design = evidenceDesigns[evidence];
              return (
                <design.icon
                  key={`ghost-card-type-${design.name}`}
                  fontSize="small"
                  sx={{
                    mx: "2px",
                    color: (theme) =>
                      excluded
                        ? theme.palette.text.disabled
                        : known_evidence.includes(evidence)
                        ? design.color
                        : theme.palette.text.secondary,
                  }}
                />
              );
            })}
          </>
        }
        // titleTypographyProps
        sx={{ py: 0 }}
        action={
          <ExpandMore
            expand={expanded}
            onClick={() => setExpanded(!expanded)}
            sx={{ my: "50%" }}
          >
            <ExpandMoreIcon
              sx={{
                color: (theme) => theme.palette.text.secondary,
              }}
            />
          </ExpandMore>
        }
      />
      <CardContent
        sx={{ py: 0, mt: 0, mb: expanded ? 1 : 0 }}
        hidden={known_evidence.length <= 1 && !expanded}
      >
        <Typography
          fontSize={12}
          textAlign="center"
          sx={{
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          {hint}
        </Typography>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            height: "20px",
            bgcolor: (theme) => theme.palette.background.default,
          }}
        >
          <Typography
            fontSize={11}
            textAlign="center"
            color={(theme) => theme.palette.text.primary}
            sx={{
              width: "100%",
            }}
          >
            Advanced identification techniques
          </Typography>
        </Box>
        <CardContent
          sx={{
            p: 1,
            border: `solid 4px ${(theme) => theme.palette.background.default}`,
          }}
        >
          <Stack spacing={0.5} direction="column">
            {adv &&
              adv.map((item, index) => (
                <div key={`${name}-adv-hunt-tech-tip-${index}`}>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <item.icon
                      sx={{
                        mx: 1,
                        border: item.forced ? "dotted 2px" : "",
                      }}
                    />
                    <Typography
                      fontSize={12}
                      color={(theme) => theme.palette.text.secondary}
                    >
                      {item.text}
                    </Typography>
                  </Box>
                  {item !== adv[adv.length - 1] && <Divider />}
                </div>
              ))}
          </Stack>
        </CardContent>
      </Collapse>
    </>
  );
};

const GhostCard = ({ type }) => {
  // Hooks
  const [expanded, setExpanded] = useState(false);
  const compact = useSelector((state) => state.meta.compactGhostCards);
  const excluded = useSelector((state) =>
    state.evidence.excludedGhosts.includes(type)
  );
  const dispatch = useDispatch();

  // Props
  const { name, evidence, hint, adv = [] } = ghostDesigns[type];

  // Component
  return (
    <Stack direction="column">
      <Card elevation={5} sx={{ width: "270px", zIndex: "" }}>
        {compact ? (
          <CompactGhostCard
            ghost={{ name, evidence, hint, adv }}
            expanded={expanded}
            setExpanded={setExpanded}
            excluded={excluded}
          />
        ) : (
          <FullGhostCard
            ghost={{ name, evidence, hint, adv }}
            expanded={expanded}
            setExpanded={setExpanded}
            excluded={excluded}
          />
        )}
      </Card>
      <Card
        elevation={2}
        sx={{
          bgcolor: (theme) => theme.palette.primary.main,
          // height: "30px",
          borderRadius: 0,
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          width: "80%",
          mx: "auto",
        }}
      >
        <Box sx={{ boxShadow: "inset 0 6px 3px -3px #00000050" }}>
          <Button
            size="small"
            startIcon={excluded ? <Add /> : <Remove />}
            sx={{
              fontSize: "11px",
              width: "100%",
              color: (theme) => `${theme.palette.text.primary}66`,
            }}
            onClick={() => {
              dispatch(updateExcludeGhost({ ghost: type }));
            }}
          >
            {excluded ? "Include" : "Exclude"}
          </Button>
        </Box>
      </Card>
    </Stack>
  );
};

export default GhostCard;
